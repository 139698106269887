<template>
    <div class="form_ct_box" v-loading.fullscreen.lock="fullscreenLoading">
        <div class="flex cofirm_box">
            <button class="cancel" @click="$router.push('/user/PublishingMedia/pubPaperMedia')">取消</button>
            <button v-if="!$route.query.id" class="affirm" @click="addMedia">确认</button>
            <button v-else class="affirm" @click="updataFn">修改</button>
        </div>

        <div class="flex ct_box">
            <div class="ct_left_box">
                <div class="flex" style="align-items: center;margin-bottom: 2rem;">
                    <p class="title_p">媒介名称：</p>
                    <el-input v-model="form.title" placeholder="请输入媒介名称"></el-input>
                </div>
                <div class="flex" style="align-items: center;margin-bottom: 2rem;">
                    <p class="title_p">媒介logo：</p>
                    <el-upload v-if="!imageUrl" class="avatar-uploader" @submit="uploadFn()" action="/api/common/upload"
                        :headers="headerObj" accept=".jpg,.png,.gif" :show-file-list="false" x:limit="1"
                        :on-success="handleAvatarSuccess" :before-upload="beforeAvatarUpload">
                        <i class="el-icon-plus avatar-uploader-icon"></i>
                    </el-upload>
                    <div class="flex upload_img" v-if="imageUrl">
                        <img :src="imageUrl" alt="" style="width: 120px" />
                        <!-- 删除图标 -->
                        <span class="delete_icon el-icon-delete" @click="beforeMove"></span>
                    </div>

                    <p style="margin: 4rem 0 0 2rem" v-if="!imageUrl">jpg、png、gif</p>
                </div>
                <div class="flex" style="align-items: center;margin-bottom: 2rem;">
                    <p class="title_p">行业类型：</p>
                    <el-select v-model="form.platform.title" @change="plat" placeholder="请选择行业类型">
                        <el-option v-for="(item, index) in platList" :key="index" :value="item.title">
                        </el-option>
                    </el-select>
                </div>
                <div class="flex" style="align-items: center;margin-bottom: 2rem;">
                    <p class="title_p">所属区域：</p>
                    <el-select v-model="form.area.title" @change="areaFn" placeholder="请选择地区">
                        <el-option v-for="(item, index) in areaList" :key="index" :value="item.title">
                        </el-option>
                    </el-select>
                </div>
                <div class="flex" style="align-items: center;margin-bottom: 2rem;">
                    <p class="title_p">媒介价：</p>
                    <el-input onkeyup="value=value.replace(/^\D*(\d*(?:\.\d{0,2})?).*$/g, '$1')"
                        oninput="if(!/^[0-9]+$/.test(value)) value=value.replace(/^(\-)*(\d+)\.(\d\d).*$/,'$1$2.$3'); if((value[0] == 0 && value[1] > 0) || value == '00')value=value.slice(1);"
                        v-model="form.price" placeholder="请输入价格"></el-input>
                </div>
                <div class="flex" style="align-items: center;margin-bottom: 2rem;">
                    <p class="title_p">案例链接：</p>
                    <el-input v-model="form.link" placeholder="请输入http:/或https:/完整地址"></el-input>
                </div>
                <!-- <div class="flex" style="align-items: center;margin-bottom: 2rem;">
                    <p class="title_p">截稿时间：</p>
                    <el-select v-model="form.timer.title" @change="timerFn" placeholder="请选择截稿时间">
                        <el-option v-for="(item, index) in timerList" :key="index" :value="item.title">
                        </el-option>
                    </el-select>
                </div> -->
            </div>
            <div class="ct_right_box">
                <!-- <div class="flex" style="align-items: center;margin-bottom: 2rem;">
                    <p class="title_p2">出稿速度：</p>
                    <el-radio v-model="form.draft_speed" :label="0">暂无</el-radio>
                    <el-radio v-model="form.draft_speed" :label="1">1小时</el-radio>
                    <el-radio v-model="form.draft_speed" :label="2">2小时</el-radio>
                    <el-radio v-model="form.draft_speed" :label="3">3小时</el-radio>
                    <el-radio v-model="form.draft_speed" :label="4">4小时</el-radio>
                    <el-radio v-model="form.draft_speed" :label="5">当天</el-radio>
                    <el-radio v-model="form.draft_speed" :label="6">次日</el-radio>
                </div> -->
                <div class="flex" style="align-items: center;margin-bottom: 2rem;">
                    <p class="title_p2">是否可发联系方式：</p>
                    <el-radio v-model="form.is_pub_contact" :label="0">否</el-radio>
                    <el-radio v-model="form.is_pub_contact" :label="1">是</el-radio>
                </div>
                <specialText />
                <div class="flex" style="align-items: center;margin-bottom: 2rem;">
                    <p class="title_p2">是否周末可发：</p>
                    <el-radio v-model="form.is_pub_weekend" :label="0">否</el-radio>
                    <el-radio v-model="form.is_pub_weekend" :label="1">是 </el-radio>
                </div>
                <div class="flex" style="">
                    <p class="title_p2">备注：</p>
                    <el-input type="textarea" :rows="2" placeholder="有修改或其他问题请填写备注，免经济纠纷" v-model="form.remark">
                    </el-input>
                </div>
                <p @click="mediumUpload" class="multiple">
                    您有多个媒体?请点击此处
                </p>
            </div>
        </div>
        <!-- 批量上传 -->
        <el-dialog title="批量上传媒体" width="35%" :append-to-body="true" :visible.sync="mediumBox" :show-close="false">
            <div style="justify-content: center;align-items: center;text-align: center;" class="flex">
                <el-upload class="upload-demo" accept=".xlsx" :headers="headerObj" drag action="/api/common/upload"
                    :on-success="Successxlsx" :before-remove="handleRemove">
                    <i class="el-icon-upload"></i>
                    <div class="el-upload__text">点击上传</div>
                </el-upload>
            </div>

            <div class="flex" style="justify-content: center;margin-top: 1rem;">
                <a class="multiple_a" target="_blank" href="/medium.xlsx">下载模板</a>

                <button class="upLoad_but" @click.stop="upLoadXlsx">确认上传</button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
    computed: {
        ...mapState(['active'])
    },
    mounted() {
        this.$store.state.active = 3
        // 请求行业类型列表
        this.curlGet('/api/medium/industry').then(res => {
            // console.log(res);
            if (res.data.code) {
                // console.log(this.$store.state.active);
                for (const i in res.data.data[this.$store.state.active]) {
                    this.platList.push({
                        id: i,
                        title: res.data.data[this.$store.state.active][i]
                    })
                }
            }
        })
        this.curlGet('/api/area/pid_list').then(res => {
            console.log(res);
            if (res.data.code) {
                // console.log(this.$store.state.active);
                for (const i in res.data.data) {
                    this.areaList.push({
                        id: i,
                        title: res.data.data[i],
                    })
                }
            }
        })
        for (let i = 10; i < 25; i++) {
            this.timerList.push({
                id: i,
                title: `${i}:00`,
            })
        }
        if (this.$route.query.id) {
            this.curlGet('/api/users/medium/detail', {
                id: this.$route.query.id
            }).then(res => {
                console.log(res);
                if (res.data.code) {
                    let data = res.data.data
                    this.platList.forEach((item, index) => {
                        if (data.industry_classify_id == item.id) {
                            this.form.platform = {
                                id: item.id,
                                title: item.title
                            }
                        }
                    })
                    this.areaList.forEach((item, index) => {
                        if (data.area_id == item.id) {
                            this.form.area = {
                                id: item.id,
                                title: item.title
                            }
                        }
                    })
                    this.timerList.forEach((item, index) => {
                        if (data.stop_draft_time == item.id) {
                            this.form.timer = {
                                id: item.id,
                                title: item.title,
                            }
                        }
                    })
                    this.form.title = data.title //标题
                    this.dialogImageUrl = data.logo //展示logo
                    this.imageUrl = data.logo // 向后端发送logo
                    this.form.price = data.medium_price //价格
                    this.form.link = data.case_url //案例链接
                    this.form.draft_speed = data.draft_speed //出稿速度
                    this.form.remark = data.remark //备注
                }
            })
        }
    },
    data() {
        return {
            //gatewayList: [], //门户类型
            platList: [], //行业类型
            areaList: [], //地区类型
            timerList: [], //截稿时间
            value: '',
            dialogImageUrl: '',
            imageUrl: '',
            dialogVisible: false,
            form: {
                //gateway: {}, //门户类型
                platform: {}, //行业
                timer: {}, //截稿时间
                draft_speed: 0,//出稿速度
                is_pub_contact: 0, //可否加联系方式
                is_pub_weekend: 0, //是否周末发稿
                title: '', //媒介名称
                area: {}, //地址
                price: undefined,//价格 
                remark: '', // 备注
                link: '', //链接
                mediaUrl: ''
            },
            headerObj: {
                'token': this.$user_info.token
            },
            fullscreenLoading: false, //加载
            mediumBox: false,
        }

    },
    methods: {
        mediumUpload() {
            this.mediumBox = true
        },
        // 批量上传媒体
        Successxlsx(res, file) {
            console.log(res, file)
            this.form.mediaUrl = res.data.url
        },
        upLoadXlsx() {
            this.curlGet('/api/users/medium/import', {
                file: this.form.mediaUrl
            }).then(res => {
                console.log(res);
                if (res.data.code) {
                    this.$message({
                        message: '上传成功,待审核',
                        type: 'success'
                    });
                    this.form.mediaUrl = ''
                    return this.mediumBox = false
                } else {
                    this.$message.error('上传失败');
                }
            })
        },
        handleRemove(file, fileList) {
            console.log(file, fileList);
            this.form.mediaUrl = ''
        },
        handleAvatarSuccess(res, file) {
            console.log(res);
            this.fullscreenLoading = true
            this.dialogImageUrl = res.data.fullurl

            this.imageUrl = URL.createObjectURL(file.raw)
            setTimeout(() => {
                this.fullscreenLoading = false
            }, 500)

        },
        beforeAvatarUpload(file) {
            let testmsg = file.name.substring(file.name.lastIndexOf(".") + 1);

            const extension = testmsg === "jpg" || testmsg === "gif" || testmsg === "png";
            const isLt2M = file.size / 1024 / 1024 < 2

            if (!extension) {
                this.$message.error('上传图片类型只能是 JPG,PNG,GIF格式!')
            }
            if (!isLt2M) {
                this.$message.error('上传图片大小不能超过 2MB!')
            }
            return extension && isLt2M
        },
        beforeMove() {
            this.$confirm('是否删除', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.imageUrl = ''
                this.$message({
                    type: 'success',
                    message: '已删除'
                });
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消'
                });
            });
        },
        gatewayFn(val) {
            let resultArr = this.gatewayList.filter((item) => {
                return item.title === val
            });
            console.log(resultArr);
            this.form.gateway = resultArr[0]
        },
        // 获取截稿时间id
		timerFn(val) {
			let resultArr = this.timerList.filter((item) => {
				return item.title === val
			})
			this.form.timer = resultArr[0]
			console.log(this.form.timer)
		},
        // 获取行业id
        plat(val) {
            let resultArr = this.platList.filter((item) => {
                return item.title === val
            });
            console.log(resultArr);
            this.form.platform = resultArr[0]

        },
        // 获取地区id
        areaFn(val) {
            let resultArr = this.areaList.filter((item) => {
                return item.title === val
            });
            console.log(resultArr);
            this.form.area = resultArr[0]
        },
        // 添加媒体
        addMedia() {
            this.$confirm('确认提交吗?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.curlPost('/api/users/medium/add', {
                    category_id: this.$store.state.active, // 导航id
                    //portal_id: this.form.gateway.id, // 门户(所属平台)类型
                    title: this.form.title, // 标题
                    medium_price: this.form.price, // 价格
                    case_url: this.form.link, // 案例链接
                    industry_classify_id: this.form.platform.id, //媒体行业类型
                    area_id: this.form.area.id, //地区
                    draft_speed: this.form.draft_speed, // 出稿速度
                    is_pub_contact: this.form.is_pub_contact, //可发联系方式
                    remark: this.form.remark, //备注
                    logo: this.dialogImageUrl, //logo
                }).then(res => {
                    console.log(res);
                    if (res.data.code) {
                        this.$message({
                            type: 'success',
                            message: res.data.msg
                        });
                        return this.$router.push('/user/PublishingMedia/pubPaperMedia')
                    } else {
                        return this.$message({
                            type: 'warning',
                            message: res.data.msg
                        });
                    }
                })

            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消'
                });
            });


        },
        updataFn() {
            this.$confirm('确认修改吗?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.curlPost('/api/users/medium/add', {
                    id: this.$route.query.id,
                    category_id: this.$store.state.active, // 导航id
                    //portal_id: this.form.gateway.id, // 门户(所属平台)类型
                    title: this.form.title, // 标题
                    medium_price: this.form.price, // 价格
                    case_url: this.form.link, // 案例链接
                    industry_classify_id: this.form.platform.id, //媒体行业类型
                    area_id: this.form.area.id, //地区
                    draft_speed: this.form.draft_speed, // 出稿速度
                    is_pub_contact: this.form.is_pub_contact, //可发联系方式
                    remark: this.form.remark, //备注
                    logo: this.dialogImageUrl, //logo
                }).then(res => {
                    console.log(res);
                    if (res.data.code) {
                        this.$message({
                            type: 'success',
                            message: res.data.msg
                        });
                        return this.$router.push('/user/PublishingMedia/pubPaperMedia')
                    } else {
                        return this.$message({
                            type: 'warning',
                            message: res.data.msg
                        });
                    }
                })
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消'
                });
            });
        }
    },
}
</script>

<style lang="scss" scoped>
@import '../../scss/AddResource.scss';
</style>